<template>
  <main class="sub">
    <div class="inner job-posting">
      <div class="tit req-type">
        <h1>포인트 내역</h1>
        <p class="stit">고객님의 포인트 내역을 확인하세요.</p>
      </div>
      <div class="sub-cont">
        <point-history-list></point-history-list>
        <point-example :exampleTbl="pointInfoTbl"></point-example>
      </div>
    </div>
  </main>
</template>

<script>
import { getPointInfo } from 'Api/modules'
import PointExample from '../partials/PointExample'
import PointHistoryList from '../partials/PointHistoryList'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['account']),
  },
  components: {
    PointExample,
    PointHistoryList,
  },
  data() {
    return {
      pointInfoTbl: [],
    }
  },
  async created() {
    const pointInfo = await getPointInfo()
    this.pointInfoTbl = pointInfo
  },
}
</script>

<style></style>
