<template>
  <div class="myp-buy-wrap type01" id="scroll">
    <ul class="nlist">
      <li>포인트는 현금처럼 사용할 수 있습니다.</li>
      <li>포인트는 아래 적립항목에 해당될 때마다 부여됩니다.</li>
      <li>부여된 포인트는 유효기간이 지나면 자동 소멸됩니다.</li>
    </ul>
    <table class="tbl01">
      <colgroup>
        <col width="180" />
        <col width="180" />
        <col width="180" />
        <col width="180" />
      </colgroup>
      <tr>
        <th>적립항목</th>
        <th>부여 포인트</th>
        <th>유효기간(개월)</th>
        <th>비고</th>
      </tr>
      <tr v-for="(item, idx) in exampleTbl" :key="`st_${idx}`">
        <td style="text-align: center">{{ item.pointPath }}</td>
        <td style="text-align: right">
          {{ `${insertCommaToNumber(item.score)}` }}
        </td>
        <td style="text-align: right">{{ item.expiration }}</td>
        <td>{{ item.remark }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    exampleTbl: Array,
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.params.backRoute) {
        document.getElementById('scroll').scrollIntoView(true)
      }
    })
  },
}
</script>

<style lang="scss">
.myp-buy-wrap.type01 {
  border: 1px solid #e5e5e5 !important;
}
</style>
